<template>
  <div
    class="detail-container"
    style="margin-top: 90px"
  >
    <div class="title-container">
      <img
        :src="state.bgBanner"
        class="title-bg"
      />
      <!-- <div class="title">{{ state.detailInfo?.title }}</div> -->
      <div
        class="other-container"
        v-if="state.type == 2"
      >
        <div class="time">发布时间：{{ state.detailInfo?.createTime }}</div>
        <div class="count">浏览次数：{{ state.detailInfo?.hit }}</div>
      </div>
    </div>
    <div class="content-container">
      <div class="content">
        <div
          class="article"
          v-html="state.detailInfo?.content"
        ></div>
        <!-- <div class="btn-container">
          <div class="item">
            <div class="tip">上一篇</div>
            <div class="article-title">本篇已是最后文章</div>
          </div>
          <div class="item">
            <div class="tip">下一篇</div>
            <div class="article-title">本篇已是最后文章</div>
          </div>
        </div> -->
      </div>
      <div
        class="news-container"
        v-if="state.arr.length > 0"
      >
        <div class="sub-title">其他动态</div>
        <div
          class="item"
          v-for="item in state.arr"
        >
          <img
            :src="item.coverImage"
            alt=""
            class="cover"
            @click="newDetails(item)"
          />
          <div class="description">
            {{ item.title }}
          </div>
          <div class="time">日期:{{ item.time }}</div>
        </div>
      </div>
    </div>
    <FooterInfo />
    <CopyrightInfo />
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, watch, ref } from 'vue'

import FooterInfo from '@/components/common/FooterInfo.vue'
import CopyrightInfo from '@/components/common/CopyrightInfo.vue'
import { baseApiUrl } from '@/config/apiConfig'
import { useRoute, useRouter } from 'vue-router'

import { getNewsApi, getDetailApi } from '@/api/api'

export default defineComponent({
  name: 'Detail',
  components: {
    FooterInfo,
    CopyrightInfo
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      detailInfo: {},
      arr: [],
      path: '',
      isNews: false,
      bgBanner: ''
    })
    onMounted(() => {
      state.path = baseApiUrl
      state.isNews = route.query.type
      let objBgBanner = {
        "3": 'https://gwht.scrzhy.com/profile/material/2022/08/19/058dfe65-096c-4737-aafe-d07994246d91.png',
        "5": 'https://gwht.scrzhy.com/profile/material/2022/08/19/d3db92b9-d28d-4ee3-b175-c7028e73bcce.png',
        "2": 'https://gwht.scrzhy.com/profile/material/2022/08/19/7f2cc1d2-43a5-4272-bf30-41a0224342a6.png'
      }
      state.bgBanner = objBgBanner[route.query.type] || 'https://gwht.scrzhy.com/profile/material/2022/08/19/a4ff44b6-70ac-4aca-b8fd-e31d2fa5be52.png'
      // https://gwht.scrzhy.com/profile/material/2022/08/19/d3db92b9-d28d-4ee3-b175-c7028e73bcce.png
      if (!route.query.id) return false
      if (!route.query.type) return false
      getDetail(route.query.id + '')
      getNews({
        articleModel: Number(route.query.type)
      })
    })

    const getNews = async (params) => {
      try {
        if (route.query.id === 38) {
          state.bgBanner = 'https://gwht.scrzhy.com/profile/material/2022/08/19/a4ff44b6-70ac-4aca-b8fd-e31d2fa5be52.png'
        }
        const result = await getNewsApi(params)
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        state.arr = data
          .map((item) => {
            const time = item.createTime
              ? item.createTime.split(' ')[0]
              : item.createTime
            const timeList = time.split('-')
            return {
              id: item.id,
              title: item.title,
              description: item.description
                ? item.description.substring(0, 120) + '...'
                : item.description,
              coverImage: state.path + item.coverImage,
              createTime: item.createTime
                ? item.createTime.split(' ')[0]
                : item.createTime,
              time:
                timeList && timeList.length === 3
                  ? `${timeList[0]}-${timeList[1]}`
                  : '',
              day: timeList && timeList.length === 3 ? timeList[2] : ''
            }
          })
          .filter((item) => item.id !== Number(route.query.id))
      } catch (e) {
        console.log('出错', e)
      }
    }

    const getDetail = async (id) => {
      try {
        console.log('id: ', id)
        const result = await getDetailApi(id)
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        console.log('成功', data)
        state.detailInfo = data
      } catch (e) {
        console.log('出错', e)
      }
    }
    watch(
      () => router.currentRoute.value.fullPath,
      (newValue, oldValue) => {
        getDetail(route.query.id + '')
        getNews({
          articleModel: Number(route.query.type)
        })
        // console.log("watch", newValue);
      },
      { immediate: true }
    )
    const newDetails = (item) => {
      router.push({
        path: '/detail',
        query: {
          id: item.id,
          type: route.query.type
        }
      })
    }
    return {
      newDetails,
      state
    }
  }
})
</script>

<style lang="scss" scoped>
.detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title-container {
    flex: 0 0 auto;
    width: 100%;
    height: 248px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 248px;
      z-index: 1;
      object-fit: cover;
    }
    .title {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      z-index: 99;
    }
    .other-container {
      margin-top: 49px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
      .time,
      .count {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .count {
        margin-left: 48px;
      }
    }
  }
  .content-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .content {
      margin-right: 40px;
      flex: 0 0 auto;
      width: 1200px;
      display: flex;
      flex-direction: column;
      .article {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        // line-height: 1.5;
        // ::v-deep h1,
        // ::v-deep h2,
        // ::v-deep h3,
        // ::v-deep h4,
        // ::v-deep h5,
        // ::v-deep h6 {
        //   line-height: 1.5;
        // }
        // ::v-deep h1 {
        //   font-size: 28px;
        //   font-weight: 700;
        // }
        // ::v-deep h2 {
        //   font-size: 26px;
        //   font-weight: 600;
        // }
        // ::v-deep h3 {
        //   font-size: 24px;
        //   font-weight: 600;
        // }
        // ::v-deep h4 {
        //   font-size: 22px;
        //   font-weight: 500;
        // }
        // ::v-deep h5 {
        //   font-size: 20px;
        // }
        // ::v-deep h6 {
        //   font-size: 18px;
        // }
        // ::v-deep p {
        //   margin-top: 10px;
        //   line-height: 1.5;
        //   text-indent: 2em;
        // }
        // ::v-deep span {
        //   line-height: 1.5;
        // }
        // ::v-deep blockquote {
        //   margin-top: 10px;
        //   text-indent: 2em;
        // }
        // ::v-deep em {
        //   display: block;
        //   text-indent: 2em;
        // }
        // ::v-deep .ql-align-center {
        //   text-align: center;
        // }
        // ::v-deep img {
        //   max-width: 100%;
        // }
        // ::v-deep p {
        //   display: inline-block;
        // }
      }
      .btn-container {
        margin-top: 34px;
        margin-bottom: 50px;
        width: 880px;
        height: 60px;
        background: #e6e7eb;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          display: flex;
          align-items: center;
          .tip,
          .article-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .article-title {
            margin-left: 10px;
            color: #666;
          }
        }
      }
    }
    .news-container {
      flex: 0 0 auto;
      width: 280px;
      display: flex;
      flex-direction: column;
      .sub-title {
        margin: 50px 0 20px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .item {
        margin-bottom: 30px;
        width: 100%;
        height: 260px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .cover {
          flex: 0 0 auto;
          width: 280px;
          height: 143px;
          overflow: hidden;
        }
        .description {
          margin: 18px 0;
          width: 100%;
          box-sizing: border-box;
          padding: 0 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .time {
          width: 100%;
          box-sizing: border-box;
          padding: 0 10px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>
